import { Flex, Text, Tooltip } from "@fluentui/react-northstar";
import React from "react";
import { useAskBuddy } from "../../../../../context/AskBuddyContext";

const PreBuiltQuestionBox = ({ question }) => {
  const { askQuestion } = useAskBuddy();
  return (
    <Flex
      className="pre-built-question-box"
      vAlign="center"
      hAlign="center"
      onClick={() => askQuestion(question)}
    >
      <Text
        content={question}
        className="pre-built-question-text"
        weight="regular"
      />
    </Flex>
  );
};

export default PreBuiltQuestionBox;
