import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import SideBar from "./Components/SideBar/SideBar";
import "./index.scss";
import { Layout } from "antd";
import Schedules from "./Components/Schedules/Schedules";
import Tutors from "./Components/Tutors/Tutors";
import { useBooking } from "../../context/BookingContext";
import MySchedules from "./Components/MySchedules/MySchedules";
import Starred from "./Components/Starred/Starred";
import BookingFilter from "./Components/BookingFilter/BookingFilter";
import BookingFilterModel from "./Components/BookingFilter/BookingFilterModel/BookingFilterModel";
import { Flex } from "@fluentui/react-northstar";
import Setting from "./Components/Setting";
import TutorRequest from "./Components/TutorRequest/TutorRequest";
import { useTutorRequest } from "../../context/TutorRequestContext";
import { AskBuddyDialog } from "./Components/AskBuddyDialog/AskBuddyDialog";
const { Content } = Layout;
const Booking = (props) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [isTabletView, setIsTabletView] = useState(
    window.innerWidth > 767 && window.innerWidth <= 991
  );
  const {
    selectedTab,
    setSelectedTab,
    isOpenBookingFiltersModel,
    setIsOpenBookingFiltersModel,
    getFilterTutorsParameters,
  } = useBooking();

  const { getProfileData, checkTutorRequest } = useTutorRequest();

  useEffect(() => {
    getFilterTutorsParameters();
    getProfileData();
    checkTutorRequest();
  }, []);

  return (
    <div className="booking-wrapper">
      <Flex column style={{ position: "sticky", top: "0", zIndex: "1000" }}>
        {selectedTab === "Tutors" ? (
          <BookingFilterModel
            open={isOpenBookingFiltersModel}
            onHandlePortal={(data) => {
              setIsOpenBookingFiltersModel(data);
            }}
          />
        ) : null}
        {selectedTab === "Tutors" ? (
          <div className="s-ctr">
            <BookingFilter
              onHandlePortal={(data) => {
                setIsOpenBookingFiltersModel(data);
              }}
              isMobileView={isMobileView}
              isTabletView={isTabletView}
            />
          </div>
        ) : null}
      </Flex>
      <Flex column>
        <Layout>
          <SideBar
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            user={{ ...props.user }}
          />
          <Content style={{ padding: "20px 20px 35px 40px", overflow: "auto" }}>
            {selectedTab === "Tutors" ? (
              <Tutors />
            ) : selectedTab === "Schedules" ? (
              <Schedules isMobileView={isMobileView} />
            ) : selectedTab === "mySchedules" ? (
              <MySchedules />
            ) : selectedTab === "starredTutors" ? (
              <Starred />
            ) : selectedTab === "setting" ? (
              <Setting user={{ ...props.user }} />
            ) : selectedTab === "tutorRequest" ? (
              <TutorRequest />
            ) : null}
          </Content>
        </Layout>
      </Flex>
      <AskBuddyDialog />
    </div>
  );
};

export default Booking;
