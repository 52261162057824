import React, { useState } from "react";
import {
  Dialog,
  TextArea,
  Button,
  Text,
  Label,
  Tooltip,
  Flex,
  CloseIcon,
  Loader,
} from "@fluentui/react-northstar";
import "./SubmitSummaryModel.scss";
import { useBooking } from "../../../../context/BookingContext";

const SubmitSummaryModel = ({ onClose, onSubmit, bookingData }) => {
  const { summary, setSummary, summaryLoading, submitSummaryModelBookingId } =
    useBooking();
  const MAX_CHARACTERS = 1000;

  const handleSummaryChange = (e, { value }) => {
    if (value.length <= MAX_CHARACTERS) {
      setSummary(value);
    }
  };

  const handleSubmit = () => {
    if (summary.trim()) {
      onSubmit(summary, submitSummaryModelBookingId);
    }
  };
  return (
    <Dialog
      open={submitSummaryModelBookingId}
      className="submit-summary-model"
      onCancel={onClose}
      header={{
        content: (
          <Text weight="bold" size="large">
            Meeting Summary
          </Text>
        ),
      }}
      content={
        <Flex column gap="gap.smaller">
          <Label
            aria-label="Meeting Summary Input"
            content="Summarize the key discussion points and outcomes"
            style={{ width: "fit-content" }}
          />
          <Tooltip
            content={`${summary.length}/${MAX_CHARACTERS} characters`}
            trigger={
              <TextArea
                fluid
                placeholder="Enter a concise summary of the meeting..."
                value={summary}
                onChange={handleSummaryChange}
                maxLength={MAX_CHARACTERS}
                disabled={summaryLoading}
                aria-label="Meeting summary text area"
                rows={12}
              />
            }
          />
          <Text
            size="small"
            color={summary.length >= MAX_CHARACTERS ? "red" : "gray"}
            align="end"
          >
            {summary.length}/{MAX_CHARACTERS}
          </Text>
          <Button
            primary
            style={{
              width: "fit-content",
              borderRadius: "5px",
              alignSelf: "flex-end",
            }}
            disabled={
              !summary.trim() ||
              summary.length > MAX_CHARACTERS ||
              summaryLoading
            }
            content={
              summaryLoading ? (
                <Flex vAlign="center" hAlign="center">
                  <Loader />
                  <Text content="Submitting..." />
                </Flex>
              ) : (
                "Submit"
              )
            }
            onClick={handleSubmit}
          />
        </Flex>
      }
      headerAction={{
        icon: <CloseIcon />,
        onClick: onClose,
        disabled: summaryLoading,
      }}
    />
  );
};

export default SubmitSummaryModel;
