import { Avatar, Chat, RobotIcon } from "@fluentui/react-northstar";
import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import i18n from "../helper/i18n";
import { useTranslation } from "react-i18next";

export const AskBuddyContext = createContext();

export const useAskBuddy = () => {
  return useContext(AskBuddyContext);
};

const languageMappings = {
  en: "English",
  "en-GB": "English (UK)",
  "en-US": "English (US)",
  es: "Spanish",
  pt: "Portuguese",
  de: "German",
  fr: "French",
  hi: "Hindi",
  // Add other languages as needed
};

export const AskBuddyProvider = (props) => {
  const { t } = useTranslation();

  const translation = t("booking").askBuddyDialog;

  const [open, setOpen] = useState(false);
  const [booking, setBooking] = useState({});
  const [conversation, setConversation] = useState({});
  const [messages, setMessages] = useState([]);
  const [question, setQuestion] = useState("");
  const [isTraining, setIsTraining] = useState(false);
  const [isLoadingAnswer, setIsLoadingAnswer] = useState(false);
  const [isLoadingPreviousMessages, setIsLoadingPreviousMessages] =
    useState(false);
  const [isLoadingPreBuiltQuestions, setIsLoadingPreBuiltQuestions] =
    useState(false);
  const [preBuiltQuestions, setPreBuiltQuestions] = useState([]);

  const askBuddyAvatar = {
    icon: <RobotIcon outline color="brand" size="large" />,
  };

  const userAvatar = {
    image: props?.user?.UrlPhoto,
  };

  // Default AskBuddy message after training
  const generateDefaultMessage = (title) => {
    return {
      contentPosition: "start",
      gutter: <Avatar {...askBuddyAvatar} />,
      message: <Chat.Message content={`${translation.askBuddyMsg} ${title}`} />,
      key: `Message---`,
    };
  };

  // Formate message for display in chat
  const formatMessage = (message, i) => {
    if (message.sender === "ai") {
      return {
        contentPosition: "start",
        gutter: <Avatar {...askBuddyAvatar} />,
        message: (
          <Chat.Message
            content={
              <div
                dangerouslySetInnerHTML={{
                  __html: JSON.parse(message.body.answer),
                }}
              />
            }
          />
        ),
        key: `ai-message-${Date.now()}-${i}`,
      };
    } else if (message.sender === "user") {
      return {
        contentPosition: "end",
        gutter: <Avatar {...userAvatar} />,
        message: <Chat.Message content={message.body.question} mine />,
        key: `user-message-${Date.now()}-${i}`,
      };
    }
    return null; // Ensure the function returns a value
  };

  const trainAskBuddy = async (booking, setFilteredBookings, setBookings) => {
    try {
      const payload = {
        id: booking.id,
        title: booking.title,
        note: booking.note,
        topics: booking.topics,
      };
      setIsTraining(true);
      const res = await axios.post(
        `${process.env.REACT_APP_EP_URL}/api/bookings/train-ask-queries-model${props.user.slug}`,
        payload,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      setIsTraining(false);
      if (res?.data?.success) {
        const conversation = res.data.result;
        setConversation(conversation);
        setMessages([generateDefaultMessage(conversation.title)]);
        setFilteredBookings((prev) =>
          prev.map((booking_) => {
            if (booking_.id === booking.id) {
              return { ...booking_, Conversations: [conversation] }; // Update the matching booking
            }
            return booking_; // Return other bookings as is
          })
        );

        setBookings((prev) =>
          prev.map((booking_) => {
            if (booking_.id === booking.id) {
              return { ...booking_, Conversations: [conversation] }; // Update the matching booking
            }
            return booking_; // Return other bookings as is
          })
        );
      }
    } catch (error) {
      setIsTraining(false);
    }
  };

  const generatePreBuiltQuestions = async (bookingId) => {
    try {
      setIsLoadingPreBuiltQuestions(true);
      const payload = {
        bookingId,
        language: languageMappings?.[i18n.language] || "English",
      };
      const res = await axios.post(
        `${process.env.REACT_APP_EP_URL}/api/bookings/generate-pre-build-up-questions/${props.user.slug}`,
        payload,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );
      if (res.data.success) {
        const preBuiltQuestions = res.data.result;
        setPreBuiltQuestions(preBuiltQuestions);
      }
      setIsLoadingPreBuiltQuestions(false);
    } catch (error) {
      setIsLoadingPreBuiltQuestions(false);
      console.error("Error in generatePreBuiltQuestions:", error);
    }
  };

  const askQueriesToAskBuddy = async (payload) => {
    try {
      setIsLoadingAnswer(true);

      const res = await axios.post(
        `${process.env.REACT_APP_EP_URL}/api/bookings/ask-queries/${props.user.slug}`,
        payload,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );

      setIsLoadingAnswer(false);

      if (res.data.success) {
        const newMessages = res.data.result.messages;
        const preBuiltQuestions = res.data.result.questions;
        setPreBuiltQuestions(preBuiltQuestions);

        // Clone existing state to maintain immutability

        // const updatedConversation = {
        //   ...conversation,
        //   messages: Object.values(conversation)
        //     ? [...conversation.messages, ...newMessages]
        //     : newMessages,
        // };

        // Format new messages for the chat
        const formattedMessages = newMessages
          .filter((message) => message.sender === "ai") // Filter only AI messages
          .map((message, i) => {
            const formattedMessage = formatMessage(message, i);
            if (formattedMessage) {
              return formattedMessage; // Only return non-null messages
            }

            return null; // Avoid adding invalid messages
          })
          .filter(Boolean); // Remove any null or undefined messages

        // Update state with the new messages
        setMessages((prevMessages) => [...prevMessages, ...formattedMessages]);

        // setConversation(updatedConversation);
      }
    } catch (error) {
      setIsLoadingAnswer(false);
      console.error("Error in askQueriesToAskBuddy:", error);
    }
  };

  const askQuestion = (question) => {
    if (question.trim() === "") return;

    const payload = {
      id: booking.id,
      conversationId: conversation.id,
      question,
      role: props.user.role,
      language: languageMappings?.[i18n.language] || "English",
    };

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        gutter: <Avatar {...userAvatar} />,
        contentPosition: "end",
        message: <Chat.Message content={question} mine />,
        key: `user-message-${Date.now()}-${prevMessages.length}`,
      },
    ]);
    setQuestion("");
    askQueriesToAskBuddy(payload);
  };

  const getMessages = async (conversationId, bookingId) => {
    try {
      setIsLoadingPreviousMessages(true);

      const res = await axios.get(
        `${
          process.env.REACT_APP_EP_URL
        }/api/bookings/get-messages/${conversationId}${
          props.user.slug
        }&bookingId=${bookingId}&language=${
          languageMappings?.[i18n.language] || "English"
        }`,
        {
          headers: { Authorization: "Bearer " + props.user.accessToken },
        }
      );

      setIsLoadingPreviousMessages(false);

      if (res.data.success) {
        const newMessages = res.data.result.messages;
        const preBuiltQuestions = res.data.result.questions;
        setPreBuiltQuestions(preBuiltQuestions);

        // Format new messages for the chat
        const formattedMessages = newMessages
          .map((message, i) => {
            return formatMessage(message, i); // Ensure the function returns a value
          })
          .filter(Boolean); // Remove any null values from the array

        // Update state with the new messages
        setMessages([...formattedMessages]);
        // setConversation(updatedConversation);
      }
    } catch (error) {
      setIsLoadingPreviousMessages(false);
      console.error("Error in askQueriesToAskBuddy:", error);
    }
  };

  // This function will set conversation history
  const loadBookingConversation = (conversation) => {
    setConversation({ ...conversation, messages: [] });
    getMessages(conversation.id, conversation.bookingId);
  };

  const handleClose = () => {
    setOpen(false);
    setConversation({});
    setBooking({});
    setMessages([]);
    setPreBuiltQuestions([]);
  };

  return (
    <AskBuddyContext.Provider
      value={{
        open,
        setOpen,
        handleClose,
        booking,
        setBooking,
        setConversation,
        trainAskBuddy,
        askQueriesToAskBuddy,
        isTraining,
        isLoadingPreviousMessages,
        isLoadingAnswer,
        conversation,
        messages,
        question,
        setQuestion,
        askQuestion,
        loadBookingConversation,
        generatePreBuiltQuestions,
        preBuiltQuestions,
        isLoadingPreBuiltQuestions,
      }}
    >
      {props.children}
    </AskBuddyContext.Provider>
  );
};
