import React from "react";
import { useAskBuddy } from "../../../../../context/AskBuddyContext";
import { useTranslation } from "react-i18next";
import { Button, Flex, Input, SendIcon } from "@fluentui/react-northstar";

const PromptInput = () => {
  const {
    isLoadingAnswer,
    isLoadingPreviousMessages,
    isTraining,
    askQuestion,
    question,
    setQuestion,
    booking,
  } = useAskBuddy();

  const { t } = useTranslation();

  const translation = t("booking").askBuddyDialog;

  return (
    <Flex fill vAlign="end" gap="gap.smaller" className="input-wrapper">
      <Input
        fluid
        value={question}
        placeholder={`${translation.questionPlaceholder[0]} ${booking.title} ${translation.questionPlaceholder[1]}`}
        disabled={
          isLoadingAnswer || isTraining || isLoadingPreviousMessages // Stops user from asking query during middle of any process
        }
        onChange={(_, { value }) => {
          setQuestion(value);
        }}
        validation={{
          message: translation.questionValidation,
          state: question ? "success" : "error",
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            askQuestion(question);
          }
        }}
      />
      <Button
        icon={<SendIcon />}
        style={{ borderRadius: 5 }}
        disabled={
          isLoadingAnswer ||
          isTraining ||
          isLoadingPreviousMessages ||
          question.trim() === "" // Stops user from sent request to ask buddy
        }
        iconOnly
        primary
        onClick={() => askQuestion(question)}
      />
    </Flex>
  );
};

export default PromptInput;
