import {
  AcceptIcon,
  Avatar,
  Button,
  Chat,
  ChevronDownIcon,
  CloseIcon,
  Dialog,
  Flex,
  FlexItem,
  Input,
  Loader,
  RobotIcon,
  SendIcon,
  Text,
  Tooltip,
} from "@fluentui/react-northstar";
import React, { useEffect, useRef, useState } from "react";
import { useAskBuddy } from "../../../../context/AskBuddyContext";
import "./AskBuddyDialog.scss";
import { useTranslation } from "react-i18next";
import PromptInput from "./components/PromptInput";
import PreBuiltQuestionBox from "./components/PreBuiltQuestionBox";
import { Col, Row } from "antd";

export const AskBuddyDialog = () => {
  const {
    open,
    isLoadingAnswer,
    isLoadingPreviousMessages,
    isTraining,
    messages,
    handleClose,
    isLoadingPreBuiltQuestions,
    preBuiltQuestions,
  } = useAskBuddy();

  const { t } = useTranslation();

  const translation = t("booking").askBuddyDialog;

  const askBuddyAvatar = {
    icon: <RobotIcon outline color="brand" size="large" />,
  };

  const chatContainerRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const trainingMessage = {
    contentPosition: "start",
    message: <Chat.Message content={`${translation.trainingMessage}`} />,
    key: `Training-Message---`,
  };

  const loadingMessage = {
    contentPosition: "start",
    gutter: <Avatar {...askBuddyAvatar} />,
    message: <Chat.Message content={`${translation.loadingMessage}`} />,
    key: `Generating-Message---`,
  };

  // const loadingPreviousMessages = {
  //   contentPosition: "start",
  //   message: <Chat.Message content={`${translation.retrievingMessage}`} />,
  //   key: `Retrieving-Message---`,
  // };

  // Scroll to the bottom when a new chat is added
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      const isScrolledToBottom =
        chatContainer.scrollHeight - chatContainer.scrollTop ===
        chatContainer.clientHeight;

      if (isScrolledToBottom) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
        setShowScrollButton(false);
      } else {
        setShowScrollButton(true);
      }
    }
  }, [messages, chatContainerRef.current]);

  // Scroll to the bottom when the button is clicked
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
      setShowScrollButton(false);
    }
  };

  // Detect scroll position and toggle the scroll button visibility
  const handleScroll = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      const isScrolledToBottom =
        chatContainer.scrollHeight - chatContainer.scrollTop <=
        chatContainer.clientHeight + 1;

      setShowScrollButton(!isScrolledToBottom);
    }
  };

  return (
    <Dialog
      id="ask-buddy-dialog"
      className="ask-buddy-dialog"
      open={open}
      content={
        <Flex
          column
          fill
          className="ask-buddy-dialog-content"
          vAlign="center"
          gap="gap.medium"
        >
          {isLoadingPreviousMessages || isLoadingPreBuiltQuestions ? (
            <Flex hAlign="center" vAlign="center" fill>
              <Text
                content={
                  isLoadingPreviousMessages
                    ? translation.retrievingMessage
                    : "Generating Pre-Built Questions..."
                }
                weight="semibold"
                size="largest"
                align="center"
              />
            </Flex>
          ) : messages.length > 0 ? (
            <>
              <FlexItem grow={1}>
                <div
                  className="chat-area-wrapper"
                  id="chat-area-wrapper"
                  ref={chatContainerRef}
                  onScroll={handleScroll}
                >
                  <Chat
                    className="chat-area"
                    styles={{
                      backgroundColor: "white", // Set background color to white
                      borderRadius: "8px", // Optional: Add border radius for better aesthetics
                      padding: "16px", // Optional: Add padding for spacing
                      border: 0,
                    }}
                    items={
                      isLoadingAnswer ? [...messages, loadingMessage] : messages
                    }
                  />
                  {showScrollButton && (
                    <Button
                      icon={<ChevronDownIcon />}
                      iconOnly
                      onClick={scrollToBottom}
                      circular
                      className="scroll-to-bottom-button"
                    />
                  )}
                </div>
              </FlexItem>
              <FlexItem>
                <Row gutter={[10, 10]}>
                  {preBuiltQuestions.map(
                    (question, i) =>
                      i < 3 && (
                        <Tooltip
                          key={`pre-built-question-${i}`}
                          content={question}
                          trigger={
                            <Col span={8}>
                              <PreBuiltQuestionBox question={question} />
                            </Col>
                          }
                          zIndex={5}
                          mountNode={
                            document.getElementsByClassName(
                              "ask-buddy-dialog-content"
                            )[0]
                          }
                        />
                      )
                  )}
                </Row>
              </FlexItem>

              <FlexItem grow={1}>
                <PromptInput />
              </FlexItem>
            </>
          ) : (
            <FlexItem>
              <Flex column style={{ padding: "0 30px" }} gap="gap.medium">
                <Text
                  content={"How can I help you?"}
                  weight="semibold"
                  size="largest"
                  align="center"
                />
                <PromptInput />
                <Flex gap="gap.smaller" wrap hAlign="center">
                  {preBuiltQuestions.map((question, i) => (
                    <PreBuiltQuestionBox
                      key={`pre-built-question-${i}`}
                      question={question}
                    />
                  ))}
                </Flex>
              </Flex>
            </FlexItem>
          )}
        </Flex>
      }
      header={
        <Flex gap="gap.small" vAlign="center" hAlign="center" fill>
          <RobotIcon outline color="brand" size="large" />
          <Text content="AskBuddy" weight="semibold" />
        </Flex>
      }
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: handleClose,
        disabled: isLoadingAnswer || isTraining || isLoadingPreviousMessages, // Stop user to close dialog if any process is in mid.
      }}
    />
  );
};
